import React, {useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, FormControl, TextField} from "@mui/material";
import LOGO from '../../image/logo.jpg'
import BACKGROUND from '../../image/loginadmin.jpg'
import axios from "axios";
import {useNavigate} from "react-router-dom";

const LoginAdmin = () => {
    const history = useNavigate()
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const handleLogin = () => {
        setIsLoading(true)
        axios.post(`${window.SystemConfig.URL}/admin/authenticate`, {
            username: username,
            password: password
        })
            .then((res) => {
                localStorage.setItem("tokenAdmin", res.data.token)
                history('/admin/user')
                setIsLoading(false)
            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error)
                setIsLoading(false)
            })
    }


    useEffect(() => {
        if (localStorage.getItem('tokenAdmin')) {
            history('/admin/user')
        }
    }, [])

    return (
        <div style={{
            minWidth: "100vw",
            minHeight: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("${BACKGROUND}")`,
            backgroundSize: "cover",
            position: "relative"
        }}>
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <div style={{
                backgroundColor: "white",
                textAlign: "center",
                width: "max-content",
                margin: "auto",
                padding: "20px 20px",
                borderRadius: "12px",
                position: "absolute",
                top: "100px",
                left:"50%",
                right:"50%",
                transform: "translateX(-50%)"
            }}>
                <div style={{fontWeight: "bold", fontSize: "24px"}}>
                    <img src={LOGO} style={{width: "100px"}} alt=""/>
                    <div style={{color: "green"}}>
                        ADMIN
                    </div>
                </div>
                <div style={{margin: "20px 0"}}>
                    <FormControl sx={{m: 1, width: '320px'}} variant="outlined">
                        <TextField
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                            id="standard-multiline-flexible"
                            label="Tên đăng nập"
                            autoComplete="current-password"
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div style={{margin: "20px 0"}}>
                    <FormControl sx={{m: 1, width: '320px'}} variant="outlined">
                        <TextField
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            id="standard-multiline-flexible"
                            label="Mật khẩu"
                            type="password"
                            autoComplete="current-password"
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div style={{margin: "20px 0"}}>
                    <Button onClick={handleLogin} size="large" sx={{m: 1, width: '320px'}} variant="contained">
                        Đăng nhập</Button>
                </div>
            </div>
        </div>
    );
};

export default LoginAdmin;
