import React, {useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, FormControl, Paper, TextField} from "@mui/material";
import LOGO from '../../image/logo.jpg'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import HeaderFinal from "../../components/HeaderFinal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Container} from "./style";
import Footer from "../../components/Footer";
import BACKGROUND from '../../image/userlogin.jpg'
import {MdVisibility} from "react-icons/md";


const LoginPage = () => {
    const history = useNavigate()
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState(true)


    useEffect(() => {
        if (localStorage.getItem('token')) {
            history('/')
        }
    }, [])

    const handleLogin = () => {
        setIsLoading(true)
        axios.post(`${window.SystemConfig.URL}/users/authenticate`, {
            username: username,
            password: password
        })
            .then((res) => {
                localStorage.setItem("token", res.data.token)
                localStorage.setItem("username", res.data.username)
                localStorage.setItem("id", res.data.id)
                let date = new Date();
                let timestamp = date.getTime();
                localStorage.setItem("time_login", timestamp)
                console.log(res)
                setTimeout(()=>{
                    history('/lessons')
                },500)
                setIsLoading(false)
            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error)
                setIsLoading(false)
            })
    }

    return (
        <div style={{
            position: "relative",
            backgroundImage: `url("${BACKGROUND}")`,
            height: "100vh",
            backgroundSize: "cover"
        }}>
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <HeaderFinal/>
            <Container>
                <div style={{
                    backgroundColor: "white",
                    textAlign: "center",
                    width: "max-content",
                    margin: "auto",
                    padding: "20px 20px",
                    borderRadius: "12px"
                }}>
                    <div style={{fontWeight: "bold", fontSize: "24px"}}>
                        <img src={LOGO} style={{width: "100px"}} alt=""/>
                        <div style={{color: "green"}}>
                            WEBSITE KỸ NĂNG SỐNG
                        </div>
                    </div>
                    <div style={{margin: "20px 0"}}>
                        <FormControl sx={{m: 1, width: '320px'}} variant="outlined">
                            <TextField
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                                id="standard-multiline-flexible"
                                label="Tên đăng nập"
                                autoComplete="current-password"
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                    <div style={{margin: "20px 0"}}>
                        <FormControl sx={{m: 1, width: '320px', position: "relative"}} variant="outlined">
                            <TextField
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                id="standard-multiline-flexible"
                                label="Mật khẩu"
                                type={type ? "password" : "text"}
                                autoComplete="current-password"
                                variant="standard"
                            />
                            <div onClick={() => {
                                setType(!type)
                            }} style={{position: "absolute", right: "12px", top: "20px"}}>
                                <MdVisibility size={24} style={{cursor: "pointer"}}/>
                            </div>
                        </FormControl>
                    </div>
                    <div style={{margin: "20px 0"}}>
                        <Button onClick={handleLogin} size="large" sx={{m: 1, width: '320px'}} variant="contained">
                            Đăng nhập</Button>
                    </div>
                </div>
            </Container>
            <Paper sx={{
                width: "100vw",
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "2",
                borderTop: "1px solid #ccc"
            }} elevation={3}>
                <Footer/>
            </Paper>
        </div>
    );
};

export default LoginPage;