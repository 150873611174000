import React, {useEffect, useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {AppBar, Backdrop, Button, CircularProgress, Dialog, Drawer, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {MdDangerous, MdVisibility} from "react-icons/md";
import axios from "axios";
import {message} from "antd";

const StyleButton = styled.div`
  margin-right: 32px;

  /* CSS */

  .button-28 {
    appearance: none;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 15px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 30px;
    min-width: 0;
    outline: none;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
  }

  .button-28:disabled {
    pointer-events: none;
  }

  .button-28:hover {
    color: black;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .button-28:active {
    box-shadow: none;
    transform: translateY(0);
  }
`

const HeaderAdmin = ({open, setOpen}) => {
    const history = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [errorChangePass, setErrorChangePass] = useState(null)
    const [changePass, setChangePass] = useState({
        oldPass: null,
        newPass: null,
        rNewPass: null
    })
    const [showPass, setShowPass] = useState({
        oldPass: false,
        newPass: false,
        rNewPass: false
    })

    useEffect(() => {
        if (changePass.rNewPass !== null && changePass.newPass !== null && changePass.rNewPass !== changePass.newPass) {
            setErrorChangePass("Mật khẩu xác nhận không trùng khớp")
        } else {
            setErrorChangePass(null)
        }
    }, [changePass])

    const onChangePass = () => {
        setIsLoading(true)
        axios.put(`${window.SystemConfig.URL}/Admin/changepassword`, {
            "OldPassword": changePass.oldPass,
            "NewPassword": changePass.newPass
        }, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                setIsLoading(false)
                setIsChangePassword(false)
                message.success("Đổi mật khẩu thành công")
            })
            .catch((error) => {
                setIsLoading(false)
                message.error("Có lỗi xảy ra")
                console.log(error)
            })
    }


    return (
        <AppBar position="absolute" open={open}>

            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}

            {
                isChangePassword && <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{position: "relative", padding: "12px"}}>
                        <MdDangerous onClick={() => {
                            setIsChangePassword(false)
                        }} size={28} style={{position: "absolute", right: "12px", cursor: "pointer"}}/>
                        <div style={{textAlign: "center", fontWeight: "bold", margin: "20px 0"}}>Đổi mật khẩu</div>
                        <div style={{marginBottom: "12px", position: "relative"}}>
                            <TextField
                                sx={{width: "300px"}}
                                value={changePass.oldPass}
                                onChange={(e) => {
                                    setChangePass({...changePass, oldPass: e.target.value})
                                }}
                                id="outlined-basic"
                                label="Mật khẩu cũ"
                                variant="outlined"
                                type={showPass.oldPass ? "text" : "password"}
                                autoComplete={false}
                            />
                            <div onClick={() => {
                                setShowPass({...showPass, oldPass: !showPass.oldPass})
                            }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                <MdVisibility size={24} style={{cursor: "pointer"}}/>
                            </div>
                        </div>
                        <div style={{marginBottom: "12px", position: "relative"}}>
                            <TextField
                                sx={{width: "300px"}}
                                value={changePass.newPass}
                                onChange={(e) => {
                                    setChangePass({...changePass, newPass: e.target.value})
                                }}
                                id="outlined-basic"
                                label="Mật khẩu mới"
                                variant="outlined"
                                type={showPass.newPass ? "text" : "password"}
                                autoComplete={false}
                            />
                            <div onClick={() => {
                                setShowPass({...showPass, newPass: !showPass.newPass})
                            }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                <MdVisibility size={24} style={{cursor: "pointer"}}/>
                            </div>
                        </div>
                        <div style={{marginBottom: "12px", position: "relative"}}>
                            <TextField
                                sx={{width: "300px"}}
                                value={changePass.rNewPass}
                                onChange={(e) => {
                                    setChangePass({...changePass, rNewPass: e.target.value})
                                }}
                                id="outlined-basic"
                                label="Xác nhận mật khẩu"
                                variant="outlined"
                                type={showPass.rNewPass ? "text" : "password"}
                                autoComplete={false}
                            />
                            <div onClick={() => {
                                setShowPass({...showPass, rNewPass: !showPass.rNewPass})
                            }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                <MdVisibility size={24} style={{cursor: "pointer"}}/>
                            </div>
                        </div>

                        <div style={{margin: "12px 0", color: "red"}}>
                            {errorChangePass ? errorChangePass : " "}
                        </div>

                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button
                                onClick={onChangePass}
                                disabled={changePass.newPass === null || changePass.rNewPass === null || changePass.rNewPass !== changePass.newPass}
                                sx={{width: "120px", margin: "0 8px"}} variant="contained" color="success">
                                Xác nhận
                            </Button>
                            <Button onClick={() => {
                                setIsChangePassword(false)
                            }} sx={{width: "120px", margin: "0 8px"}} variant="outlined" color="error">
                                Hủy
                            </Button>
                        </div>
                    </div>
                </Dialog>
            }

            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div style={{display: "flex"}}>
                        <StyleButton
                            onClick={() => {
                                history('/admin/user')
                            }}
                        >
                            <div className={"button-28"}>
                                Tài khoản
                            </div>
                        </StyleButton>
                        <StyleButton
                            onClick={() => {
                                history('/admin/file')
                            }}
                        >
                            <div className={"button-28"}>
                                Bài giảng
                            </div>
                        </StyleButton>
                        <StyleButton
                            onClick={() => {
                                history('/admin/blog')
                            }}
                        >
                            <div className={"button-28"}>
                                Bài viết
                            </div>
                        </StyleButton>


                    </div>
                    <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                        <div style={{fontSize: "16px", cursor: "pointer", marginRight: "20px"}} onClick={() => {
                            setIsChangePassword(true)
                        }}>
                            Đổi mật khẩu
                        </div>
                        <div style={{fontSize: "16px", cursor: "pointer", color: "orange"}} onClick={() => {
                            localStorage.removeItem('tokenAdmin')
                            history('/admin')
                        }}>
                            Đăng xuất
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default HeaderAdmin;