import {BrowserRouter, Routes, Route} from 'react-router-dom'
import React, {useEffect, useState} from 'react';
import './App.css';
import LoginPage from "./container/LoginPage";
import BaiGiang from "./container/BaiGiang";
import AdminPageUser from "./container/AdminPageUser";
import AdminPageFile from "./container/AdminPageFile";
import ViewPPPTX from "./container/ViewPPPTX";
import LoginAdmin from "./container/LoginAdmin";
import Home from "./container/Home";
import AdminBlog from "./container/AdminBlog";
import CreateAndEditBlog from "./container/CreateAndEditBlog";
import PreviewBlog from "./container/PreviewBlog";
import Contact from "./container/Contact";
import ListBlog from "./container/ListBlog";
// import callApiHistory from "./components/api";


function is24HoursApart(timestamp1, timestamp2) {
    // Calculate the difference in milliseconds
    let differenceInMillis = Math.abs(timestamp1 - timestamp2);

    // Convert milliseconds to hours
    let differenceInHours = differenceInMillis / (1000 * 60 * 60);

    // Check if the difference is 24 hours or more
    return differenceInHours >= 24;
}

function App() {

    useEffect(() => {
        // callApiHistory({id: 4, action:"Đăng nhập"})
        let currentTimestamp = Date.now(); // Current timestamp in milliseconds
        let pastTimestamp = localStorage.getItem("time_login")

        if (pastTimestamp) {
            if (is24HoursApart(currentTimestamp, pastTimestamp)) {
                localStorage.removeItem("token");
            }
        }
    }, [])

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/lessons" element={<BaiGiang/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/file/:id" element={<ViewPPPTX/>}/>


                    <Route path="/blog/:id" element={<PreviewBlog/>}/>
                    <Route path="/list-blog" element={<ListBlog/>}/>


                    <Route path="/admin" element={<LoginAdmin/>}/>
                    <Route path="/admin/user" element={<AdminPageUser/>}/>
                    <Route path="/admin/file" element={<AdminPageFile/>}/>

                    <Route path="/admin/blog" element={<AdminBlog/>}/>
                    <Route path="/admin/create-blog" element={<CreateAndEditBlog/>}/>
                    <Route path="/admin/edit-blog/:id" element={<CreateAndEditBlog/>}/>

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
