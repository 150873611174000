import styled from "styled-components";
import {Button} from "antd";

export const ButtonAntd = styled(Button)`
  width: 200px;
  margin: 40px 20px;
`


export const Container = styled.div`

  .ant-upload-list {
      
  }
`