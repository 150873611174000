import styled from "styled-components";


export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &#PptUpperToolbar.LeftButtonDock.DownloadWithPowerPoint-Medium20 {
    display: none !important;
  }

  #PowerPointViewerHeroDock {
    display: none !important;
  }

`