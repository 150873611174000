import React from 'react';

export default function SimpleMap({data}) {

    return (
        <div style={{height: '100%', width: '100%', borderRadius: '8px'}}>
            <iframe
                style={{width: "100%", height: "100%", borderRadius: '8px', border: "1px solid #ccc"}}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBD7fPHpEBO9Ysiw-LDzdE5GgBUnSEeLV4&q=${data}`}>
            </iframe>
        </div>
    );
}
