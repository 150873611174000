import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Container} from "./style";
import {
    MdMenu,
    MdKeyboardBackspace,
    MdManageAccounts,
    MdInsertDriveFile,
    MdOutlineCreateNewFolder,
    MdDelete,
    MdOutlineCancel,
    MdEditDocument,
    MdOutlineVisibility,
    MdCreateNewFolder, MdAddCircle,
} from "react-icons/md";
import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    InputLabel, MenuItem, Dialog,
    Select,
} from "@mui/material";
import {useEffect, useState} from "react";
import {AiFillCloseSquare, AiOutlineToTop, AiOutlineUpload} from "react-icons/ai";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {message, Tooltip} from "antd";
import BmosTable from "../../components/BmosTable";
import {Upload} from 'antd';
import {ButtonAntd} from "../AdminPage/style";
import InputFile from "../../components/InputFile";
import moment from "moment";
import MenuAdmin from "../../components/MenuAdmin";
import HeaderAdmin from "../../components/HeaderAdmin";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AdminBlog() {
    const history = useNavigate()

    const [open, setOpen] = React.useState(false);
    const [listNamHoc, setListNamHoc] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [listBlog, setListBlog] = useState([])
    const [dataDelete, setDataDelete] = useState(null)
    const [errorUpload, setErrorUpload] = useState(false)
    const [successUpload, setSuccessUpload] = useState(false)


    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
    });


    const column = [
        {
            title: 'Tên bài viết',
            dataIndex: 'name',
            align: 'left',
            sorter: true,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isEnable',
            align: 'left',
            sorter: true,
            render: (text, record) => {
                if (text) {
                    return <div style={{
                        padding: "4px 8px",
                        backgroundColor: "green",
                        color: "white",
                        width: "max-content",
                        borderRadius: "4px"
                    }}>Hiển thị</div>
                } else {
                    return <div style={{
                        padding: "4px 8px",
                        backgroundColor: "red",
                        color: "white",
                        width: "max-content",
                        borderRadius: "4px"
                    }}>Không hiển thị</div>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createAt',
            align: 'left',
            sorter: true,
            render: (text, record) => {
                return (moment(text).format("YYYY-MM-DD HH:mm:ss"))
            }
        },
        {
            title: 'Chức năng',
            dataIndex: 'action',
            width: 160,
            align: 'center',
            render: (text, record) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Xem bài viết"}>
                            <MdOutlineVisibility onClick={() => {
                                history(`/blog/${record.id}`)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Sửa bài viết"}>
                            <MdEditDocument onClick={() => {
                                history(`/admin/edit-blog/${record.id}`)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Xóa bài viết"}>
                            <MdDelete onClick={() => {
                                setDataDelete(record)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]


    useEffect(() => {
        if (localStorage.getItem("tokenAdmin")) {
            setIsLoading(true)
            axios.get(`${window.SystemConfig.URL}/Blog/getAll`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                .then((res) => {
                    setIsLoading(false)
                    setListBlog(res.data)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.clear()
                        history('/')
                    }
                    setIsLoading(false)
                    setListNamHoc([])
                })
        } else {
            history('/admin')
        }
    }, [])


    const toggleDrawer = () => {
        setOpen(!open);
    };


    const onDeleteBlog = () => {
        axios.delete(`${window.SystemConfig.URL}/Blog/delete/${dataDelete.id}`, {
            headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}
        })
            .then(function (response) {
                console.log(response);
                setIsLoading(false)
                message.success("XÓA BÀI GIẢNG THÀNH CÔNG", [2])
                setDataDelete(null)
            })
            .catch(function (response) {
                if (response.response.status === 401) {
                    localStorage.clear()
                    history('/')
                }
                //handle error
                console.log(response);
                setIsLoading(false)
                message.error("XÓA GIẢNG LỖI", [2])
            });
    }


    return (
        <Container>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <HeaderAdmin open={open} setOpen={setOpen}/>

                    {isLoading && <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000}}
                        open={true}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>}


                    {
                        dataDelete && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative", padding: "12px"}}>
                                <MdOutlineCancel onClick={() => {
                                    setDataDelete(null)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <div style={{
                                    textAlign: "center",
                                }}>
                                    <div style={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: "red",
                                        margin: "60px 0 32px",
                                    }}>
                                        XÁC NHẬN XÓA BÀI VIẾT
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Button color="success" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={onDeleteBlog}
                                                variant="contained"
                                        >
                                            Xác nhận
                                        </Button>
                                        <Button color="error" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={() => {
                                                    setDataDelete(null)
                                                }}
                                                variant="contained">
                                            Hủy
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    }

                    {
                        errorUpload && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative"}}>
                                <MdOutlineCancel onClick={() => {
                                    setErrorUpload(false)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <div style={{
                                    color: "red",
                                    height: "100px",
                                    width: "400px",
                                    lineHeight: "100px",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>
                                    LỖI KHÔNG XÓA ĐƯỢC BÀI VIẾT
                                </div>
                            </div>
                        </Dialog>
                    }

                    {
                        successUpload && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative"}}>
                                <MdOutlineCancel onClick={() => {
                                    setSuccessUpload(false)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"gray"}/>
                                <div style={{
                                    color: "green",
                                    height: "100px",
                                    width: "400px",
                                    lineHeight: "100px",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>
                                    ĐẨY BÀI VIẾT THÀNH CÔNG
                                </div>
                            </div>
                        </Dialog>
                    }

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: 'calc(100vh - 84px)',
                            width: '100vw',
                            padding: "84px 22px 22px 27px",
                            background: "#ffffff"
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "space-between", marginBottom: "12px"}}>
                            <span style={{fontWeight: "bold"}}>Quản lý bài viết</span>
                            <Button onClick={() => {
                                history('/admin/create-blog')
                            }} variant="outlined" startIcon={<MdAddCircle/>}>
                                Thêm mới bài viết
                            </Button>
                        </div>
                        {listNamHoc && <BmosTable
                            isLoading={false}
                            disableClickRowExpand={false}
                            columns={column}
                            data={listBlog}
                            pagination
                            totalRecord={listBlog.length}
                        />}

                    </div>
                </Box>
            </ThemeProvider>
        </Container>
    );
}

