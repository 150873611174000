import React from 'react';
import HeaderFinal from "../../components/HeaderFinal";
import SimpleMap from "../../components/Map";
import Footer from "../../components/Footer";
import {Button, Paper} from "@mui/material";

const Contact = () => {
    return (
        <div style={{overflow: "hidden", marginTop: "102px"}}>
            <HeaderFinal/>

            <div style={{width: "80%", margin: "auto", display: "flex", justifyContent: "center"}}>
                <div style={{width: "45%", height: "500px", marginRight: "20px"}}>
                    <SimpleMap data={encodeURIComponent("7PH78J58+MC")}/>
                </div>
                <div>
                    <div style={{marginBottom: "8px", fontWeight: "bold"}}>
                        Liên hệ
                    </div>
                    <div style={{fontWeight: "bold", color: "green", fontSize: "24px", marginBottom: "24px"}}>
                        Trung tâm kỹ năng sống VP-Edu
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        Địa chỉ: Số 68 - Nguyễn Du - Khai Quang - Vĩnh Yên - Vĩnh Phúc
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        Số điện thoại :097 475 22 26
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        Email: giaoducvinhphuc@gmail.com
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        Facebook: facebook.com/trungtamkynangsongvpedu
                    </div>
                </div>
            </div>

            <Paper sx={{
                width: "100vw",
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "2",
                borderTop: "1px solid #ccc"
            }} elevation={3}>
                <Footer/>
            </Paper>


        </div>
    );
};

export default Contact;