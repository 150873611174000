import styled from "styled-components";

export const Container = styled.div`
  //background-image: url("../../image/background_login.jpg");

  padding-top: 102px;


  .ky_nang_song_la_gi {
    margin-top: 80px;
    display: flex;
    justify-content: center;

    .text {
      margin-right: 20px;
      width: 35%;
      font-weight: normal;

      h1 {
        color: #92d050;
        font-size: 20px;
      }

      h2 {
        font-size: 16px;
        font-weight: normal;
      }
    }

    img {
      margin-left: 20px;
      width: auto;
      height: 400px;
    }
  }

`