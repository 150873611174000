import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;

  .row {
    padding-top: 60px;
  }

  .khoi {
    width: 100vw;
    background-color: #61dafb;
  }

  .row_khoi {
    height: 80px;
    margin: 0 100px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }

  .khoi_item {
    width: 100px;
    height: 44px;
    background-color: #273556;
    border-radius: 8px;
    text-align: center;
    line-height: 44px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }

  .no_item {
    font-size: 24px;
    font-weight: bold;
  }

  .khoa_hoc {
    display: flex;
  }

  .thoi_gian {
    min-height: calc(100vh - 140px);
    width: 200px;
    background-color: rosybrown;
  }

  .right {
    width: calc(100vw - 200px);
    overflow: hidden;
    padding: 24px;
  }

  .bai_giang {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 50px;
    margin-left: 50px;

    .row_bai_giang {
      margin: auto;
      display: grid;
      justify-content: center;
      gap: 40px;
      grid-template-columns: 1fr 1fr;
    }

    .item_bai_giang {
      width: max-content;

      .tuan {
        text-align: center;
        width: 240px;
        height: 60px;
        line-height: 60px;
        background-color: #273556;
        font-size: 24px;
        color: white;
      }

      .button_bai_giang {
        display: flex;

        div {
          cursor: pointer;
          width: 50%;
          height: 50px;
          color: white;
          line-height: 50px;
          text-align: center;
          background-color: orange;
        }

        div:last-child {
          background-color: green;
        }
      }
    }
  }

`