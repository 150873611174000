import React, {useEffect} from 'react';
import {Container} from "./style";

const InputFile = ({title, onChange}) => {
    useEffect(() => {
        const dropContainer = document.getElementById("dropcontainer")
        if (dropContainer) {
            const fileInput = document.getElementById("images")

            dropContainer.addEventListener("dragover", (e) => {
                // prevent default to allow drop
                e.preventDefault()
            }, false)

            dropContainer.addEventListener("dragenter", () => {
                dropContainer.classList.add("drag-active")
            })

            dropContainer.addEventListener("dragleave", () => {
                dropContainer.classList.remove("drag-active")
            })

            dropContainer.addEventListener("drop", (e) => {
                e.preventDefault()
                dropContainer.classList.remove("drag-active")
                fileInput.files = e.dataTransfer.files
            })
        }
    })
    return (
        <Container>
            <label htmlFor="images" className="drop-container" id="dropcontainer">
                <span className="drop-title">{title}</span>
                <input onChange={onChange} type="file" id="images" required/>
            </label>
        </Container>
    );
};

export default InputFile;