import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    Avatar,
    Backdrop, Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select
} from "@mui/material";
import {Container} from "./style";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";


const BaiGiang = () => {
    const history = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [listNamHoc, setListNamHoc] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [khoi, setKhoi] = useState(null)
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [tuan, setTuan] = useState(null)

    const [lesson, setLesson] = useState(null)

    const [isXemHe, setIsXemHe] = useState(false)


    useEffect(() => {
        if (localStorage.getItem("token")) {
            setIsLoading(true)
            axios.get(`${window.SystemConfig.URL}/users`, {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}})
                .then((res) => {
                    console.log(JSON.parse(res.data.metaData && res.data.metaData.replace(/'/g, '"'))?.isXemHe)
                    setIsXemHe(JSON.parse(res.data.metaData && res.data.metaData.replace(/'/g, '"'))?.isXemHe)
                    console.log()
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.clear()
                        history('/')
                    }
                    console.log(error.response.status)
                    setIsLoading(false)
                })


            axios.get(`${window.SystemConfig.URL}/users/getLesson`, {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}})
                .then((res) => {
                    setListNamHoc(res.data.result)
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.clear()
                        history('/')
                    }
                    console.log(error.response.status)
                    setListNamHoc([])
                    setIsLoading(false)
                })
        } else {
            history('/')
        }
    }, [])


    useEffect(() => {
        if (khoi === null || year === null || month === null || tuan === null) {
            setLesson(null)
        } else {
            listNamHoc && listNamHoc.map((item) => {
                if (item.name === khoi) {
                    return item.years.map((yearItem) => {
                        if (yearItem.name === year) {
                            return yearItem.months.map((monthItem) => {
                                if (monthItem.name === month) {
                                    return monthItem.weeks.map((itemWeek) => {
                                        if (itemWeek.name === tuan) {
                                            return itemWeek.lessons.map((itemLessons) => {
                                                setIsLoading(true)
                                                setTimeout(() => {
                                                    setIsLoading(false)
                                                    console.log(itemLessons)
                                                    setLesson(itemLessons)
                                                }, 500)
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }, [khoi, year, month, tuan])


    return (
        <Container>
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <Paper sx={{position: 'fixed', top: 0, left: 0, right: 0}} elevation={3}>
                <div style={{height: "60px", backgroundColor: "#ccc"}}>
                    <div style={{
                        margin: "0 100px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center"
                    }}>
                        <div onClick={() => {
                            history('/')
                        }} style={{
                            color: "#009b6d",
                            lineHeight: "60px",
                            fontWeight: "bold",
                            fontSize: "24px",
                            cursor: "pointer"
                        }}>Kỹ
                            năng sống
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{
                                lineHeight: "60px",
                                marginRight: "12px",
                                fontWeight: "bold"
                            }}>{localStorage.getItem("username")}</div>
                            <Avatar>U</Avatar>
                            <div onClick={() => {
                                localStorage.clear()
                                history('/')
                            }} style={{marginLeft: "12px", cursor: "pointer"}}>Đăng xuất
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>


            <div style={{marginTop: "100px"}}>
                <div style={{display: "flex", justifyContent: 'center'}}>
                    <Box sx={{minWidth: 120, marginRight: "12px"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Chọn khối</InputLabel>
                            <Select
                                sx={{minWidth: "200px"}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={khoi}
                                label="Chọn khối"
                                onChange={(e) => {
                                    setKhoi(e.target.value)
                                }}
                            >
                                {listNamHoc && listNamHoc.map((item) => {
                                    return (
                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{minWidth: 120, marginRight: "12px"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Chọn năm học</InputLabel>
                            <Select
                                disabled={khoi === null}
                                sx={{minWidth: "200px"}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Chọn năm học"
                                onChange={(e) => {
                                    setYear(e.target.value)
                                }}
                            >
                                {listNamHoc && listNamHoc.map((item) => {
                                    if (item.name === khoi) {
                                        return item.years.map((yearItem) => {
                                            return (
                                                <MenuItem value={yearItem.name}>{yearItem.name}</MenuItem>
                                            )
                                        })
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Box>


                    <Box sx={{minWidth: 120, marginRight: "12px"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Chọn tháng</InputLabel>
                            <Select
                                disabled={khoi === null || year === null}
                                sx={{minWidth: "200px"}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Chọn tháng"
                                onChange={(e) => {
                                    setMonth(e.target.value)
                                }}
                            >
                                {listNamHoc && listNamHoc.map((item) => {
                                    if (item.name === khoi) {
                                        return item.years.map((yearItem) => {
                                            if (yearItem.name === year) {
                                                return yearItem.months.map((monthItem) => {
                                                    if(monthItem.name === "HÈ" && !isXemHe){
                                                        return null
                                                    }else {
                                                        return (
                                                            <MenuItem style={{textTransform: "uppercase"}}
                                                                      value={monthItem.name}>{monthItem.name}</MenuItem>
                                                        )
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{minWidth: 120, marginRight: "12px"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Chọn bài</InputLabel>
                            <Select
                                disabled={khoi === null || year === null || month === null}
                                sx={{minWidth: "200px"}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tuan}
                                label="Chọn bài"
                                onChange={(e) => {
                                    setTuan(e.target.value)
                                }}
                            >
                                {listNamHoc && listNamHoc.map((item) => {
                                    if (item.name === khoi) {
                                        return item.years.map((yearItem) => {
                                            if (yearItem.name === year) {
                                                return yearItem.months.map((monthItem) => {
                                                    if (monthItem.name === month) {
                                                        return monthItem.weeks.map((itemWeek) => {
                                                            return (
                                                                <MenuItem
                                                                    value={itemWeek.name}>{itemWeek.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Box>

                </div>


                {lesson && <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                    {lesson.powerpointPath ? <div style={{cursor: "pointer", marginRight: "20px"}}>
                            <div style={{textAlign: "center"}}>
                                <div style={{
                                    height: "80px",
                                    lineHeight: "80px",
                                    backgroundColor: "#78bd2cb8",
                                    width: "200px",
                                    fontWeight: "bold",
                                    color: "white"
                                }}>BÀI GIẢNG TRÌNH CHIẾU
                                </div>
                                <div
                                    style={{
                                        width: "200px",
                                        height: "40px",
                                        lineHeight: "40px",
                                        backgroundColor: "green",
                                        fontWeight: "bold",
                                        color: "white"
                                    }}
                                    onClick={() => {
                                        window.open(`${window.SystemConfig.LINK + "/" + lesson.powerpointPath}`, "mozillaWindow", "popup")
                                    }}>
                                    XEM
                                </div>
                            </div>
                        </div> :
                        <div style={{marginRight: "20px", color: "orange", fontWeight: "bold"}}>
                            CHƯA CÓ BÀI GIẢNG TRÌNH CHIẾU
                        </div>}


                    {lesson.pdfPath ?
                        <div style={{textAlign: "center", marginLeft: "20px"}}>
                            <div style={{
                                height: "80px",
                                lineHeight: "80px",
                                backgroundColor: "orange",
                                width: "200px",
                                fontWeight: "bold",
                                color: "white"
                            }}>BÀI GIẢNG GIÁO ÁN
                            </div>
                            <div
                                style={{
                                    width: "200px",
                                    height: "40px",
                                    lineHeight: "40px",
                                    backgroundColor: "green",
                                    fontWeight: "bold",
                                    color: "white"
                                }}
                                onClick={() => {
                                    window.open(`${window.SystemConfig.LINK}/${lesson.pdfPath}`, "mozillaWindow", "popup")
                                }}>
                                XEM
                            </div>
                        </div> :
                        <div style={{marginLeft: "20px", color: "orange", fontWeight: "bold"}}>
                            CHƯA CÓ BÀI GIẢNG GIÁO ÁN
                        </div>
                    }
                </div>}

            </div>


        </Container>
    );
};

export default BaiGiang;
