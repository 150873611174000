import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Container} from "./style";
import {
    MdMenu,
    MdKeyboardBackspace,
    MdManageAccounts,
    MdInsertDriveFile, MdOutlineCreateNewFolder, MdDelete, MdOutlineCancel,
} from "react-icons/md";
import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    InputLabel, MenuItem, Dialog,
    Select, OutlinedInput, useTheme,
} from "@mui/material";
import {useEffect, useState} from "react";
import {AiFillCloseSquare, AiOutlineToTop, AiOutlineUpload} from "react-icons/ai";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {message, Tooltip} from "antd";
import BmosTable from "../../components/BmosTable";
import {Upload} from 'antd';
import {ButtonAntd} from "../AdminPage/style";
import InputFile from "../../components/InputFile";
import MenuAdmin from "../../components/MenuAdmin";
import HeaderAdmin from "../../components/HeaderAdmin";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AdminPageFile() {
    const history = useNavigate()

    const [open, setOpen] = React.useState(false);
    const [listNamHoc, setListNamHoc] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [khoi, setKhoi] = useState(null)
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [tuan, setTuan] = useState(null)

    const [lesson, setLesson] = useState(null)
    const [pdfFile, setPdfFile] = useState(null)
    const [powerpointFile, setPowerPointFile] = useState(null)
    const [listLesson, setListLesson] = useState([])
    const [listLessonTable, setListLessonTable] = useState([])
    const [dataUpload, setDataUpload] = useState(null)
    const [dataDelete, setDataDelete] = useState(null)
    const [errorUpload, setErrorUpload] = useState(false)
    const [successUpload, setSuccessUpload] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [listUser, setListUser] = useState([])
    const [personName, setPersonName] = React.useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
    });


    const column = [
        {
            title: 'Khối',
            dataIndex: 'blockName',
            align: 'left',
            render: (text) => {
                return <p style={{textTransform:"capitalize"}}>{text}</p>
            },
            sorter: true,
        },
        {
            title: 'Năm học',
            dataIndex: 'yearName',
            align: 'left',
            render: (text) => {
                return <p style={{textTransform:"capitalize"}}>{text}</p>
            },
            sorter: true,
        },
        {
            title: 'Tháng học',
            dataIndex: 'month',
            align: 'left',
            render: (text) => {
                return <p style={{textTransform:"capitalize"}}>{text}</p>
            },
            sorter: true,
        },
        {
            title: 'Tuần học',
            dataIndex: 'week',
            align: 'left',
            render: (text) => {
                return <p style={{textTransform:"capitalize"}}>{text}</p>
            },
            sorter: true,
        },
        {
            title: 'PDF file',
            dataIndex: 'pdfPath',
            align: 'left',
            sorter: true,
            render: (text, record) => {
                if (text === null) {
                    return <div style={{color: "red", fontWeight: "bold"}}>
                        Chưa có bài giảng
                    </div>
                }
                if (text !== null) {
                    return <div onClick={() => {
                        window.open(`${window.SystemConfig.LINK}/${text}`, "mozillaWindow", "popup")
                    }} style={{
                        cursor: "pointer",
                        padding: "8px 12px",
                        backgroundColor: "orange",
                        width: "max-content",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "6px"
                    }}>
                        VIEW PDF
                    </div>
                }
            },
        },
        {
            title: 'Powerpoint file',
            dataIndex: 'powerpointPath',
            align: 'left',
            sorter: true,
            render: (text, record) => {
                if (text === null) {
                    return <div style={{color: "red", fontWeight: "bold"}}>
                        Chưa có bài giảng
                    </div>
                }
                if (text !== null) {
                    return <div onClick={() => {
                        window.open(`${window.SystemConfig.LINK + "/" + text}`, "mozillaWindow", "popup")
                    }} style={{
                        cursor: "pointer",
                        padding: "8px 12px",
                        backgroundColor: "orange",
                        width: "max-content",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "6px"
                    }}>
                        VIEW POWERPOINT
                    </div>
                }
            },
        },
        {
            title: 'Chức năng',
            dataIndex: 'action',
            width: 160,
            align: 'center',
            render: (text, record) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Upload bài giảng"}>
                            <MdOutlineCreateNewFolder onClick={() => {
                                setDataUpload(record)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Xóa bài giảng"}>
                            <MdDelete onClick={() => {
                                setDataDelete(record)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]


    useEffect(() => {
        if (localStorage.getItem("tokenAdmin")) {
            setIsLoading(true)

            axios.get(`${window.SystemConfig.URL}/Admin/getall`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                .then((res) => {
                    let arrayUser = []
                    res.data.map((item) => {
                        if (item.role === 1) {
                            arrayUser.push(item)
                            return null
                        } else return null
                    })
                    setListUser(arrayUser)
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.clear()
                        history('/')
                    }
                })



            axios.get(`${window.SystemConfig.URL}/users/getLesson`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                .then((res) => {
                    setIsLoading(false)
                    setListNamHoc(res.data.result)
                    let array = []
                    res.data.result.map((item) => {
                        item.years.map((yearItem) => {
                            yearItem.months.map((monthItem) => {
                                monthItem.weeks.map((itemWeek) => {
                                    itemWeek.lessons.map((itemLessons) => {
                                        array.push(itemLessons)
                                    })
                                })
                            })
                        })
                    })
                    setListLesson(array)
                    setListLessonTable(array)

                    console.log(array)
                })
                .catch((error) => {
                    setIsLoading(false)
                    setListNamHoc([])
                })
        } else {
            history('/admin')
        }
    }, [])

    useEffect(() => {
        let array = []

        console.log("Khối " + khoi)
        console.log("Năm " + year)
        console.log("Tháng " + month)
        console.log("Tuần " + tuan)

        if (khoi === null && year === null && month === null && tuan === null) {
            listNamHoc.map((item) => {
                item.years.map((yearItem) => {
                    yearItem.months.map((monthItem) => {
                        monthItem.weeks.map((itemWeek) => {
                            itemWeek.lessons.map((itemLessons) => {
                                array.push(itemLessons)
                            })
                        })
                    })
                })
            })
        } else {
            listLesson.map((item) => {
                if ((khoi && item.blockName === khoi) && (year ? item.yearName === year : true) && (month ? item.month === month : true) && (tuan ? item.week === tuan : true)) {
                    array.push(item)
                }
            })
        }

        setListLessonTable(array)
    }, [khoi, year, month, tuan, listLesson, listNamHoc])


    const toggleDrawer = () => {
        setOpen(!open);
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];


    const uploadBaiGiang = () => {
        setIsLoading(true)
        let bodyFormData = new FormData();

        bodyFormData.append('id', dataUpload.id);
        bodyFormData.append('Name', dataUpload.Name);
        bodyFormData.append('PdfName', "PDF NAME");
        bodyFormData.append('PdfFormFile', pdfFile);
        bodyFormData.append('PowerpointName', "POWERPOINT NAME");
        bodyFormData.append('PowerpointFormFile', powerpointFile);
        bodyFormData.append('IsEnablePdf', true);
        bodyFormData.append('IsEnablePowerPoint', true);
        bodyFormData.append('IsEnable', true);

        console.log(bodyFormData)


        axios({
            method: "post",
            url: `${window.SystemConfig.URL}/admin/updateLesson`,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("tokenAdmin")}`
            }
        })
            .then(function (response) {
                console.log(response);
                setIsLoading(false)
                message.success("ĐẨY BÀI GIẢNG THÀNH CÔNG", [2])
                axios.get(`${window.SystemConfig.URL}/users/getLesson`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                    .then((res) => {
                        setListNamHoc(res.data.result)
                        let array = []
                        res.data.result.map((item) => {
                            item.years.map((yearItem) => {
                                yearItem.months.map((monthItem) => {
                                    monthItem.weeks.map((itemWeek) => {
                                        itemWeek.lessons.map((itemLessons) => {
                                            array.push(itemLessons)
                                        })
                                    })
                                })
                            })
                        })
                        setListLesson(array)
                    })
                    .catch((error) => {
                        setListNamHoc([])
                    })
                setPdfFile(null)
                setPowerPointFile(null)
                setDataUpload(null)
                setSuccessUpload(true)

            })
            .catch(function (response) {
                //handle error
                console.log(response);
                setIsLoading(false)
                setErrorUpload(true)
                message.error("ĐẨY BÀI GIẢNG LỖI", [2])
            });
    }


    const onDeleteLesson = () => {
        axios.delete(`${window.SystemConfig.URL}/admin/deleteWeek`, {
            params: {
                block: Number(dataDelete.blockName.split(" ")[1]),
                yearId: Number(dataDelete.yearId),
                month: Number(dataDelete.month.split(" ")[1]),
                week: Number(dataDelete.week.split(" ")[1])
            },
            headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}
        })
            .then(function (response) {
                console.log(response);
                setIsLoading(false)
                message.success("XÓA BÀI GIẢNG THÀNH CÔNG", [2])
                axios.get(`${window.SystemConfig.URL}/users/getLesson`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                    .then((res) => {
                        setListNamHoc(res.data.result)
                        let array = []
                        res.data.result.map((item) => {
                            item.years.map((yearItem) => {
                                yearItem.months.map((monthItem) => {
                                    monthItem.weeks.map((itemWeek) => {
                                        itemWeek.lessons.map((itemLessons) => {
                                            array.push(itemLessons)
                                        })
                                    })
                                })
                            })
                        })
                        setListLesson(array)
                    })
                    .catch((error) => {
                        setListNamHoc([])
                    })
                setPdfFile(null)
                setPowerPointFile(null)
                setDataDelete(null)
                setSuccessUpload(true)

            })
            .catch(function (response) {
                //handle error
                console.log(response);
                setIsLoading(false)
                setErrorUpload(true)
                message.error("XÓA GIẢNG LỖI", [2])
            });
    }


    return (
        <Container>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <HeaderAdmin open={open} setOpen={setOpen}/>

                    {isLoading && <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000}}
                        open={true}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>}


                    {
                        isChangePassword && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative", padding: "12px"}}>
                                <MdOutlineCancel onClick={() => {
                                    setDataDelete(null)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <div style={{
                                    textAlign: "center",
                                }}>
                                    <div style={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: "red",
                                        margin: "60px 0 32px",
                                    }}>
                                        XÁC NHẬN XÓA BÀI GIẢNG TRONG {dataDelete.week}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Button color="success" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={onDeleteLesson}
                                                variant="contained"
                                        >
                                            Xác nhận
                                        </Button>
                                        <Button color="error" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={() => {
                                                    setDataDelete(null)
                                                }}
                                                variant="contained">
                                            Hủy
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    }


                    {
                        dataDelete && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative", padding: "12px"}}>
                                <MdOutlineCancel onClick={() => {
                                    setDataDelete(null)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <div style={{
                                    textAlign: "center",
                                }}>
                                    <div style={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: "red",
                                        margin: "60px 0 32px",
                                    }}>
                                        XÁC NHẬN XÓA BÀI GIẢNG TRONG {dataDelete.week}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Button color="success" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={onDeleteLesson}
                                                variant="contained"
                                        >
                                            Xác nhận
                                        </Button>
                                        <Button color="error" sx={{width: "150px", margin: "auto 12px"}}
                                                onClick={() => {
                                                    setDataDelete(null)
                                                }}
                                                variant="contained">
                                            Hủy
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    }

                    {
                        errorUpload && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative"}}>
                                <MdOutlineCancel onClick={() => {
                                    setErrorUpload(false)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <div style={{
                                    color: "red",
                                    height: "100px",
                                    width: "400px",
                                    lineHeight: "100px",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>
                                    LỖI KHÔNG ĐẨY ĐƯỢC BÀI GIẢNG
                                </div>
                            </div>
                        </Dialog>
                    }

                    {
                        successUpload && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative"}}>
                                <MdOutlineCancel onClick={() => {
                                    setSuccessUpload(false)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"gray"}/>
                                <div style={{
                                    color: "green",
                                    height: "100px",
                                    width: "400px",
                                    lineHeight: "100px",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>
                                    ĐẨY BÀI GIẢNG THÀNH CÔNG
                                </div>
                            </div>
                        </Dialog>
                    }

                    {
                        dataUpload && <Dialog
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{position: "relative"}}>
                                <MdOutlineCancel onClick={() => {
                                    setDataUpload(null)
                                }} size={32}
                                                 style={{position: "absolute", right: "8px", top: "8px", cursor: "pointer"}}
                                                 color={"red"}/>
                                <h1 style={{textAlign: "center", fontSize: "28px"}}>Thêm bài giảng</h1>
                                <div style={{
                                    width: "500px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        margin: "24px auto",
                                        justifyContent: "center"
                                    }}>
                                        <InputFile onChange={(e) => {
                                            setPowerPointFile(e.target.files[0])
                                        }} title={"BÀI GIẢNG POWERPOINT"}/>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        margin: "24px auto",
                                        justifyContent: "center"
                                    }}>
                                        <InputFile onChange={(e) => {
                                            setPdfFile(e.target.files[0])
                                        }} title={"BÀI GIẢNG PDF"}/>
                                    </div>
                                </div>


                                {/*<div style={{marginLeft: "50px"}}>*/}
                                {/*    <FormControlLabel control={<Switch defaultChecked/>} label="Cho phép xem"/>*/}
                                {/*</div>*/}
                                {/*<div style={{marginLeft: "50px"}}>*/}
                                {/*    <FormControl sx={{m: 1, width: 400}}>*/}
                                {/*        <InputLabel id="demo-multiple-name-label">Chặn tài khoản</InputLabel>*/}
                                {/*        <Select*/}
                                {/*            labelId="demo-multiple-name-label"*/}
                                {/*            id="demo-multiple-name"*/}
                                {/*            multiple*/}
                                {/*            value={personName}*/}
                                {/*            onChange={handleChange}*/}
                                {/*            input={<OutlinedInput label="Chặn tài khoản"/>}*/}
                                {/*            MenuProps={MenuProps}*/}
                                {/*            renderValue={(selected) => selected.join(', ')}*/}
                                {/*        >*/}
                                {/*            {listUser.map((user) => {*/}
                                {/*                return <MenuItem key={user.username} value={user.username}>*/}
                                {/*                    <Checkbox checked={personName.indexOf(user.username) > -1}/>*/}
                                {/*                    <ListItemText primary={user.username}/>*/}
                                {/*                </MenuItem>*/}
                                {/*            })}*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*</div>*/}


                                <div style={{display: "flex", justifyContent: "center", margin: "32px 0"}}>
                                    <Button onClick={uploadBaiGiang} disabled={pdfFile === null || powerpointFile === null}
                                            variant="contained"
                                            endIcon={<AiOutlineToTop/>}>
                                        Đẩy bài giảng
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                    }


                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: 'calc(100vh - 84px)',
                            width: '100vw',
                            padding: "84px 22px 22px 27px",
                            background: "#ffffff"
                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            marginBottom: "32px"
                        }}>
                            <div style={{fontWeight: "bold", marginRight: "32px"}}>Quản lý bài giảng</div>
                            <div style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignContent: "center",
                                alignItems: "center"
                            }}>
                                <Box sx={{minWidth: 120, marginRight: "12px"}}>
                                    <FormControl fullWidth>
                                        <InputLabel size={"small"} id="demo-simple-select-label">Chọn khối</InputLabel>
                                        <Select
                                            size={"small"}
                                            sx={{minWidth: "200px"}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={khoi}
                                            label="Chọn khối"
                                            onChange={(e) => {
                                                setKhoi(e.target.value)
                                            }}
                                        >
                                            {listNamHoc && listNamHoc.map((item) => {
                                                return (
                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{minWidth: 120, marginRight: "12px"}}>
                                    <FormControl fullWidth>
                                        <InputLabel size={"small"} id="demo-simple-select-label">Chọn năm
                                            học</InputLabel>
                                        <Select
                                            size={"small"}
                                            disabled={khoi === null}
                                            sx={{minWidth: "200px"}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={year}
                                            label="Chọn năm học"
                                            onChange={(e) => {
                                                setYear(e.target.value)
                                            }}
                                        >
                                            {listNamHoc && listNamHoc.map((item) => {
                                                if (item.name === khoi) {
                                                    return item.years.map((yearItem) => {
                                                        return (
                                                            <MenuItem value={yearItem.name}>{yearItem.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>


                                <Box sx={{minWidth: 120, marginRight: "12px"}}>
                                    <FormControl fullWidth>
                                        <InputLabel size={"small"} id="demo-simple-select-label">Chọn tháng</InputLabel>
                                        <Select
                                            size={"small"}
                                            disabled={khoi === null || year === null}
                                            sx={{minWidth: "200px"}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={month}
                                            label="Chọn tháng"
                                            onChange={(e) => {
                                                setMonth(e.target.value)
                                            }}
                                        >
                                            {listNamHoc && listNamHoc.map((item) => {
                                                if (item.name === khoi) {
                                                    return item.years.map((yearItem) => {
                                                        if (yearItem.name === year) {
                                                            return yearItem.months.map((monthItem) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={monthItem.name}>{monthItem.name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    })
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{minWidth: 120, marginRight: "12px"}}>
                                    <FormControl fullWidth>
                                        <InputLabel size={"small"} id="demo-simple-select-label">Chọn tuần</InputLabel>
                                        <Select
                                            size={"small"}
                                            disabled={khoi === null || year === null || month === null}
                                            sx={{minWidth: "200px"}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tuan}
                                            label="Chọn tuần"
                                            onChange={(e) => {
                                                setTuan(e.target.value)
                                            }}
                                        >
                                            {listNamHoc && listNamHoc.map((item) => {
                                                if (item.name === khoi) {
                                                    return item.years.map((yearItem) => {
                                                        if (yearItem.name === year) {
                                                            return yearItem.months.map((monthItem) => {
                                                                if (monthItem.name === month) {
                                                                    return monthItem.weeks.map((itemWeek) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={itemWeek.name}>{itemWeek.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>

                            </div>
                        </div>


                        {lesson && <div style={{margin: "auto"}}>
                            <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                                <div>
                                    <div>
                                        Bài giảng trình chiếu:
                                    </div>
                                    <input
                                        onChange={(e) => {
                                            setPowerPointFile(e.target.files[0])
                                        }}
                                        type="file"
                                    />
                                </div>
                                <div>
                                    <div>
                                        Bài giảng giáo án:
                                    </div>
                                    <input
                                        onChange={(e) => {
                                            setPdfFile(e.target.files[0])
                                        }}
                                        type="file"
                                    />
                                </div>
                            </div>


                            <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                                <Button onClick={uploadBaiGiang} disabled={pdfFile === null || powerpointFile === null}
                                        variant="contained"
                                        endIcon={<AiOutlineToTop/>}>
                                    Đẩy bài giảng
                                </Button>
                            </div>
                        </div>}

                        {listNamHoc && <BmosTable
                            isLoading={false}
                            disableClickRowExpand={false}
                            columns={column}
                            data={listLessonTable}
                            pagination
                            totalRecord={listLessonTable.length}
                        />}

                    </div>
                </Box>
            </ThemeProvider>
        </Container>
    );
}

