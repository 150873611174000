import React, {useEffect, useState} from 'react';
import {Container} from "./style";
import HeaderFinal from "../../components/HeaderFinal";
import Footer from "../../components/Footer";
import axios from "axios";
import {Backdrop, CircularProgress, Paper} from "@mui/material";
import MOCK_BLOG from "../../image/mock_blog.jpg";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const ListBlog = () => {
    const history = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [listBlog, setListBlog] = useState([])
    useEffect(() => {
        setIsLoading(true)
        axios.get(`${window.SystemConfig.URL}/Blog/getAll`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                setIsLoading(false)
                let array = res.data.sort((a, b) => b.createAt - a.createAt)
                setListBlog(array)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [])
    return (
        <Container>
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <HeaderFinal/>
            <div style={{margin: "20px auto", fontWeight: "bold", width: "80%"}}>
                DANH SÁCH CÁC BÀI VIẾT
            </div>
            <div className={"row"}>
                {listBlog.map((item) => {
                    return (
                        <div onClick={() => {
                            history(`/blog/${item.id}`)
                        }} className={"item"}>
                            <img
                                src={window.SystemConfig.LINK + "/" + item.img || MOCK_BLOG}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = MOCK_BLOG;
                                }}
                                alt=""
                            />
                            <div className={"text"}>
                                <h1>{item.name}</h1>
                                <h2>{item.description}</h2>
                                <div className="time">
                                    {moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <Paper sx={{
                width: "100vw",
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "2",
                borderTop: "1px solid #ccc"
            }} elevation={3}>
                <Footer/>
            </Paper>
        </Container>
    );
};

export default ListBlog;