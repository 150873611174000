import styled from "styled-components";

export const Container = styled.div`
  margin-top: 102px;
  overflow: hidden;

  .blog {
    display: block;
    position: relative;
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, .3);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .3);
    padding: 20px 35px;
    margin-top: 20px;
  }

  .name {
    width: 100%;
    text-align: center;
    color: #337ab7;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid rgba(97, 97, 97, 0.3);
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .ql-align-center {

  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;

    .item {
      cursor: pointer;
      width: 32%;
      display: flex;
      border: 1px solid #cccccc;
      justify-content: space-between;
      position: relative;

      .time {
        position: absolute;
        bottom: 10px;
        right: 6px;
        font-size: 12px;
        font-style: italic;
      }

      img {
        height: 180px;
        width: 35%;
        object-fit: cover;
      }

      .text {
        width: 62%;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 12px;
        }

        h2 {
          display: -webkit-box;
          max-width: 400px;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          font-weight: normal;
        }
      }
    }


  }
`