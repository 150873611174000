import React, {useEffect, useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import {Backdrop, Button, CircularProgress, Paper, TextField} from "@mui/material";
import {MdCreateNewFolder} from "react-icons/md";
import EditorToolbar, {modules, formats} from "../../components/EditorToolbar";
import axios from "axios";
import 'react-quill/dist/quill.snow.css';
import {useLocation, useNavigate} from "react-router-dom";
import {message} from "antd";

Quill.register('modules/imageResize', ImageResize);
const CreateAndEditBlog = () => {
    const location = useLocation();
    document.title = 'Blog';
    const history = useNavigate()
    const asPath = window.location.pathname;
    const lastIndex = asPath.lastIndexOf('/');
    const id = asPath.substring(lastIndex + 1, asPath.length);
    const [isLoading, setIsLoading] = useState(false)

    const [htmlElement, setHtmlElement] = useState({value: null});
    const [imgUrl, setImageUrl] = useState(null)
    const [value, setValue] = useState({
        "name": null,
        "filePath": null,
        "img": null,
        "description": null,
        "isEnable": true,
    });
    const handleChange = value => {
        setHtmlElement({value});
    };

    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
    });

    useEffect(() => {
        if (asPath.includes("edit")) {
            setIsLoading(true)
            axios.get(`${window.SystemConfig.URL}/Blog/getById/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                .then(response => {
                    setValue(response.data)
                    axios.get(`${window.SystemConfig.URL}/blog/getBlockDataFile/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                        .then(response => {
                            setIsLoading(false)
                            setHtmlElement({value: response.data.data})
                        })
                        .catch(error => {
                            console.error('Error uploading file:', error);
                        });
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                });
        }
    }, [id])


    const onSubmit = () => {
        const fileName = 'input_content.txt';
        const fileContent = htmlElement.value;

        const blob = new Blob([fileContent], {type: 'text/plain'});

        const formData = new FormData();
        formData.append('File', blob, fileName);
        formData.append('name', value.name)
        formData.append('description', value.description)
        if (imgUrl !== null) {
            formData.append('Img', imgUrl)

        }
        formData.append('isEnable', true)

        axios.post(`${window.SystemConfig.URL}/Blog/create`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then(response => {
                console.log('File uploaded successfully!', response.data);
                message.success("Tạo bài viết thành công")
                history(-1)
            })
            .catch(error => {
                message.error("Tạo bài viết lỗi")
                console.error('Error uploading file:', error);
            });
    }

    const onUpdate = () => {
        const fileName = 'input_content.txt';
        const fileContent = htmlElement.value;

        const blob = new Blob([fileContent], {type: 'text/plain'});
        const formData = new FormData();
        formData.append('id', value.id)
        formData.append('File', blob, fileName)
        formData.append('name', value.name)
        formData.append('description', value.description)
        if (imgUrl !== null) {
            formData.append('Img', imgUrl)

        }
        formData.append('isEnable', true)

        axios.put(`${window.SystemConfig.URL}/Blog/update`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then(response => {
                console.log('File uploaded successfully!', response.data);
                message.success("Sửa bài viết thành công")
                history(-1)
            })
            .catch(error => {
                message.error("Sửa bài viết lỗi")
                console.error('Error uploading file:', error);
            });
    }

    return (
        <div style={{padding: "12px", width: "80vw", margin: "auto", marginBottom: "100px"}}>
            {asPath.includes("edit") ?
                <div style={{fontWeight: "bold", marginBottom: "12px"}}>
                    SỬA BÀI VIẾT
                </div> :
                <div style={{fontWeight: "bold", marginBottom: "12px"}}>
                    THÊM BÀI VIẾT
                </div>}
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
               <textarea
                   placeholder={"Tên bài viết"}
                   value={value.name}
                   onChange={(e) => {
                       setValue({...value, name: e.target.value})
                   }}
                   style={{
                       width: "60%",
                       backgroundColor: "white",
                       fontFamily: 'Lato',
                       borderRadius: "6px",
                       padding: "6px",
                       fontSize: "16px"
                   }}
                   rows="3" cols="50"
               />
            </div>
            <div style={{display: "flex", alignContent: "center", alignItems: "center", margin: "12px 0 8px"}}>
                <div style={{marginRight: "12px"}}>Ảnh mô tả :</div>
                <input
                    accept="image/*"
                    onChange={(e) => {
                        setImageUrl(e.target.files[0])
                    }}
                    type="file"
                    placeholder={"Ảnh mô tả"}
                />
            </div>
            <textarea
                placeholder={"Mô tả bài viết"}
                value={value.description}
                onChange={(e) => {
                    setValue({...value, description: e.target.value})
                }}
                style={{
                    width: "60%",
                    backgroundColor: "white",
                    fontFamily: 'Lato',
                    borderRadius: "6px",
                    fontSize: "16px",
                    padding: "6px",
                    marginBottom: "12px"
                }}
                rows="3" cols="50"
            />

            <div className="text-editor">
                <EditorToolbar/>
                <ReactQuill
                    theme="snow"
                    value={htmlElement.value}
                    onChange={handleChange}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                />
            </div>

            <Paper sx={{
                width: "100vw",
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "2",
                borderTop: "1px solid #ccc"
            }} elevation={3}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    backgroundColor: "white",
                    margin: "6px 0"
                }}>
                    <Button
                        onClick={() => {
                            history(-1)
                        }}
                        size={"small"}
                        sx={{margin: "0 12px"}}
                        variant="outlined"
                    >
                        Hủy (Quay lại)
                    </Button>
                    {asPath.includes('edit') ? <Button
                        onClick={onUpdate}
                        size={"small"}
                        sx={{margin: "0 12px"}}
                        variant="contained"
                    >
                        Lưu lại
                    </Button> : <Button
                        onClick={onSubmit}
                        size={"small"}
                        sx={{margin: "0 12px"}}
                        variant="contained"
                    >
                        Tạo mới
                    </Button>}
                </div>
            </Paper>
        </div>
    )
};

export default CreateAndEditBlog;