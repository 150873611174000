import React from 'react';

const Footer = () => {
    return (
        <div style={{
            height: "60px",
            width: "100vw",
            backgroundColor: "#007452",
        }}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                lineHeight: "60px",
                color: "white"
            }}>
                Copyright © 2023 Kỹ năng sống Vĩnh Phúc. All right reserved.
            </div>
        </div>
    );
};

export default Footer;