import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
    MdAddCircle, MdDangerous, MdVisibility
} from "react-icons/md";
import UserPage from "../UserPage";
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {AiFillCloseSquare} from "react-icons/ai";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import HeaderAdmin from "../../components/HeaderAdmin";

const defaultTheme = createTheme();

export default function AdminPageUser() {
    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
    });
    const history = useNavigate()

    const [open, setOpen] = React.useState(false);
    const [listUser, setListUser] = useState([])
    const [isCreateUser, setIsCreateUser] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dataEdit, setDataEdit] = useState(null)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [errorChangePass, setErrorChangePass] = useState(null)
    const [changePass, setChangePass] = useState({
        oldPass: null,
        newPass: null,
        rNewPass: null
    })
    const [showPass, setShowPass] = useState({
        oldPass: false,
        newPass: false,
        rNewPass: false
    })

    useEffect(() => {
        if (localStorage.getItem("tokenAdmin")) {
            handleGetAllUser()
        } else {
            history('/admin')
        }
    }, [])


    const handleGetAllUser = () => {
        setIsLoading(true)
        axios.get(`${window.SystemConfig.URL}/Admin/getall`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                let arrayUser = []
                res.data.map((item) => {
                    if (item.role === 1) {
                        arrayUser.push(item)
                        return null
                    } else return null
                })
                setListUser(arrayUser)
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear()
                    history('/')
                }
                setIsLoading(false)
                console.log(error)
            })
    }

    const handleCreateUser = (data) => {
        axios.post(`${window.SystemConfig.URL}/Admin/register`, {
            ...data,
        }, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                setIsLoading(false)
                handleGetAllUser()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear()
                    history('/')
                }
                setIsLoading(false)
                console.log(error)
            })
    }

    const handleEditUser = (id, data) => {
        axios.put(`${window.SystemConfig.URL}/Admin/updateUser?id=${id}`, {
            ...data,
        }, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                setIsLoading(false)
                handleGetAllUser()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear()
                    history('/')
                }
                setIsLoading(false)
                console.log(error)
            })

    }

    useEffect(() => {
        if (changePass.rNewPass === null || changePass.newPass === null || changePass.rNewPass !== changePass.newPass) {
            setErrorChangePass("Mật khẩu xác nhận không trùng khớp")
        } else {
            setErrorChangePass(null)
        }
    }, [changePass])

    const onChangePass = () => {
        setIsLoading(true)
        axios.put(`${window.SystemConfig.URL}/Admin/changepassword`, {
            "OldPassword": changePass.oldPass,
            "NewPassword": changePass.newPass
        }, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                setIsLoading(false)
                setIsChangePassword(false)
                message.success("Đổi mật khẩu thành công")
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear()
                    history('/')
                }
                setIsLoading(false)
                message.error("Có lỗi xảy ra")
                console.log(error)
            })
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <HeaderAdmin open={open} setOpen={setOpen}/>
                {
                    isChangePassword && <Dialog
                        open={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div style={{position: "relative", padding: "12px"}}>
                            <MdDangerous onClick={() => {
                                setIsChangePassword(false)
                            }} size={28} style={{position: "absolute", right: "12px", cursor: "pointer"}}/>
                            <div style={{textAlign: "center", fontWeight: "bold", margin: "20px 0"}}>Đổi mật khẩu</div>
                            <div style={{marginBottom: "12px", position: "relative"}}>
                                <TextField
                                    sx={{width: "300px"}}
                                    value={changePass.oldPass}
                                    onChange={(e) => {
                                        setChangePass({...changePass, oldPass: e.target.value})
                                    }}
                                    id="outlined-basic"
                                    label="Mật khẩu cũ"
                                    variant="outlined"
                                    type={showPass.oldPass ? "text" : "password"}
                                    autoComplete={false}
                                />
                                <div onClick={() => {
                                    setShowPass({...showPass, oldPass: !showPass.oldPass})
                                }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                    <MdVisibility size={24} style={{cursor: "pointer"}}/>
                                </div>
                            </div>
                            <div style={{marginBottom: "12px", position: "relative"}}>
                                <TextField
                                    sx={{width: "300px"}}
                                    value={changePass.newPass}
                                    onChange={(e) => {
                                        setChangePass({...changePass, newPass: e.target.value})
                                    }}
                                    id="outlined-basic"
                                    label="Mật khẩu mới"
                                    variant="outlined"
                                    type={showPass.newPass ? "text" : "password"}
                                    autoComplete={false}
                                />
                                <div onClick={() => {
                                    setShowPass({...showPass, newPass: !showPass.newPass})
                                }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                    <MdVisibility size={24} style={{cursor: "pointer"}}/>
                                </div>
                            </div>
                            <div style={{marginBottom: "12px", position: "relative"}}>
                                <TextField
                                    sx={{width: "300px"}}
                                    value={changePass.rNewPass}
                                    onChange={(e) => {
                                        setChangePass({...changePass, rNewPass: e.target.value})
                                    }}
                                    id="outlined-basic"
                                    label="Xác nhận mật khẩu"
                                    variant="outlined"
                                    type={showPass.rNewPass ? "text" : "password"}
                                    autoComplete={false}
                                />
                                <div onClick={() => {
                                    setShowPass({...showPass, rNewPass: !showPass.rNewPass})
                                }} style={{position: "absolute", right: "12px", top: "16px"}}>
                                    <MdVisibility size={24} style={{cursor: "pointer"}}/>
                                </div>
                            </div>

                            <div style={{margin: "12px 0", color: "red"}}>
                                {errorChangePass ? errorChangePass : " "}
                            </div>

                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    onClick={onChangePass}
                                    disabled={changePass.newPass === null || changePass.rNewPass === null || changePass.rNewPass !== changePass.newPass}
                                    sx={{width: "120px", margin: "0 8px"}} variant="contained" color="success">
                                    Xác nhận
                                </Button>
                                <Button onClick={() => {
                                    setIsChangePassword(false)
                                }} sx={{width: "120px", margin: "0 8px"}} variant="outlined" color="error">
                                    Hủy
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }

                {isLoading && <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>}

                {isCreateUser && <PopUpCreateUser
                    onCreate={(data) => {
                        setIsLoading(true)
                        handleCreateUser(data)
                        setIsCreateUser(false)
                    }}

                    onClose={() => {
                        setIsCreateUser(false)
                    }}/>}

                {/*<PopUpHistoryUser/>*/}

                {dataEdit && <PopUpEditUser
                    onEdit={(data) => {
                        setIsLoading(true)
                        handleEditUser(dataEdit.id, data)
                        setDataEdit(null)
                    }}
                    data={dataEdit}

                    onClose={() => {
                        setDataEdit(null)
                    }}/>}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: 'calc(100vh - 84px)',
                        width: '100%',
                        padding: "84px 22px 22px 27px",
                        background: "#ffffff"
                    }}
                >
                    <div style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: "12px 0",
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <span>Quản lý tài khoản</span>
                        <Button onClick={() => {
                            setIsCreateUser(true)
                        }} variant="outlined" startIcon={<MdAddCircle/>}>
                            Thêm mới tài khoản
                        </Button>
                    </div>
                    {!isLoading && <UserPage listUser={listUser} onEdit={setDataEdit}/>}
                </div>
            </Box>
        </ThemeProvider>
    );
}


const PopUpHistoryUser = ({onClose, onCreate}) => {
    function createData(time, content) {
        return {time, content};
    }

    const rows = [
        createData('08:00 2024-08-05', 'Công việc A'),
        createData('09:00 2024-08-05', 'Công việc B'),
        createData('10:00 2024-08-05', 'Công việc C'),
        createData('11:00 2024-08-05', 'Công việc D'),
    ];

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{padding: "12px 24px", position: "relative"}}>
                <div style={{position: "absolute", right: "4px", top: "4px"}}><AiFillCloseSquare onClick={onClose}
                                                                                                 color={"red"}
                                                                                                 size={32}/></div>
                <p style={{textAlign: "center"}}>LỊCH SỬ TÀI KHOẢN</p>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 500}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Thời gian</TableCell>
                                <TableCell>Nội dung hoạt động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.time}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.time}
                                    </TableCell>
                                    <TableCell>{row.content}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    )
}

const PopUpCreateUser = ({onClose, onCreate}) => {
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState("12345")
    const [isEnable, setIsEnable] = useState(true)
    const [isXemHe, setIsXemHe] = useState(true)
    const [permission, setPermission] = useState([false, false, false, false, false, false, false, false, false])
    const handleChangePermission = (index, value) => {
        let array = [...permission]
        array[index] = value
        setPermission(array)
    }


    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{padding: "12px 24px", position: "relative"}}>
                <div style={{position: "absolute", right: "4px", top: "4px"}}><AiFillCloseSquare onClick={onClose}
                                                                                                 color={"red"}
                                                                                                 size={32}/></div>
                <p style={{textAlign: "center"}}>THÊM TÀI KHOẢN</p>
                <div style={{margin: "12px 0"}}>
                    <TextField
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                        id="outlined-basic"
                        label="Tên đăng nhập"
                        variant="outlined"
                        autoComplete={false}
                    />
                </div>
                <div style={{margin: "12px 0"}}>
                    <TextField
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        id="outlined-password-input"
                        label="Mật khẩu"
                        type="text"
                        autoComplete="current-password"
                    />
                </div>

                <div style={{margin: "12px 0"}}>
                    {permission.map((item, index) => {
                        return (<FormControlLabel
                            label={`Khối ${index + 1}`}
                            control={<Checkbox checked={item} onChange={(event) => {
                                handleChangePermission(index, event.target.checked)
                            }}/>}
                        />)
                    })}
                </div>

                <div style={{margin: "12px 0"}}>
                    <FormControlLabel control={<Switch onChange={(e) => {
                        setIsXemHe(e.target.checked)
                    }} checked={isXemHe}/>} label="Cho phép xem hè"/>
                </div>

                <div style={{margin: "12px 0"}}>
                    <FormControlLabel control={<Switch onChange={(e) => {
                        setIsEnable(e.target.checked)
                    }} checked={isEnable}/>} label="Kích hoạt"/>
                </div>
                <div style={{margin: "12px 0"}}>
                    <Button onClick={() => {
                        onCreate({
                            username: username,
                            password: password,
                            isEnable: isEnable,
                            permission: permission,
                            metaData: JSON.stringify({isXemHe: isXemHe, history: []})
                        })
                    }} disabled={username === null || username === "" || password === null || password === ""}
                            sx={{width: "100%"}} variant="contained" color="success">
                        Tạo
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}


const PopUpEditUser = ({onClose, onEdit, data}) => {
    const [username, setUsername] = useState(data?.username)
    const [password, setPassword] = useState(null)
    const [isEnable, setIsEnable] = useState(data?.isEnable)
    const [permission, setPermission] = useState(data?.permission.split(",").map(item => item === "True"))
    const [isXemHe, setIsXemHe] = useState(JSON.parse(data.metaData && data.metaData.replace(/'/g, '"'))?.isXemHe)
    const handleChangePermission = (index, value) => {
        let array = [...permission]
        array[index] = value
        setPermission(array)
    }


    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{padding: "12px 24px", position: "relative"}}>
                <div style={{position: "absolute", right: "4px", top: "4px"}}><AiFillCloseSquare onClick={onClose}
                                                                                                 color={"red"}
                                                                                                 size={32}/></div>
                <p style={{textAlign: "center"}}>SỬA TÀI KHOẢN</p>
                <div style={{margin: "12px 0"}}>
                    <TextField
                        disabled={true}
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                        id="outlined-basic"
                        label="Tên đăng nhập"
                        variant="outlined"
                        autoComplete={false}
                    />
                </div>


                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{margin: "12px 0", display: "flex", flexDirection: "column"}}>
                        {permission.map((item, index) => {
                            if (index < 5) {
                                return (<FormControlLabel
                                    label={`Khối ${index + 1}`}
                                    control={<Checkbox checked={item} onChange={(event) => {
                                        handleChangePermission(index, event.target.checked)
                                    }}/>}
                                />)
                            } else {
                                return null
                            }
                        })}
                    </div>
                    <div style={{margin: "12px 0", display: "flex", flexDirection: "column"}}>
                        {permission.map((item, index) => {
                            if (index > 4) {
                                return (<FormControlLabel
                                    label={`Khối ${index + 1}`}
                                    control={<Checkbox checked={item} onChange={(event) => {
                                        handleChangePermission(index, event.target.checked)
                                    }}/>}
                                />)
                            } else {
                                return null
                            }
                        })}
                    </div>
                </div>

                {/*<div style={{margin: "12px 0", display: "flex", flexDirection: "column"}}>*/}
                {/*    {permission.map((item, index) => {*/}
                {/*        return (<FormControlLabel*/}
                {/*            label={`Khối ${index + 1}`}*/}
                {/*            control={<Checkbox checked={item} onChange={(event) => {*/}
                {/*                handleChangePermission(index, event.target.checked)*/}
                {/*            }}/>}*/}
                {/*        />)*/}
                {/*    })}*/}
                {/*</div>*/}


                <div style={{margin: "12px 0"}}>
                    <FormControlLabel control={<Switch onChange={(e) => {
                        setIsXemHe(e.target.checked)
                    }} checked={isXemHe}/>} label="Cho phép xem hè"/>
                </div>

                <div style={{margin: "12px 0"}}>
                    <FormControlLabel control={<Switch onChange={(e) => {
                        setIsEnable(e.target.checked)
                    }} checked={isEnable}/>} label="Kích hoạt"/>
                </div>
                <div style={{margin: "12px 0"}}>
                    <FormControlLabel control={<Switch onChange={(e) => {
                        if (e.target.checked) {
                            setPassword('12345')
                        } else {
                            setPassword(null)
                        }
                    }}/>} label="Khôi phục mật khẩu"/>
                </div>
                <div style={{margin: "12px 0"}}>
                    <Button onClick={() => {
                        onEdit({
                            username: username,
                            password: password,
                            isEnable: isEnable,
                            permission: permission,
                            metaData: JSON.stringify({
                                ...JSON.parse(data.metaData && data.metaData.replace(/'/g, '"')),
                                isXemHe: isXemHe
                            })
                        })
                    }} disabled={username === null || username === ""}
                            sx={{width: "100%"}} variant="contained" color="success">
                        Tạo
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
