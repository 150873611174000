import React, {useState} from 'react';
import {Container, MenuCon} from "./style";
import LOGO from "../../image/logo.png";
import {Drawer, Paper} from "@mui/material";
import {message} from "antd";
import {useNavigate} from "react-router-dom";

const HeaderFinal = () => {
    const asPath = window.location.pathname;
    const history = useNavigate()

    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
    });
    return (
        <Paper sx={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000}} elevation={3}>
            <Container>
                <div className="row_header">
                    <a href="/"><img className={"logo"} src={LOGO} alt=""/></a>
                    <div className="row_menu">
                        <div className="menu">
                            <div style={{height: "64px"}}>
                                <div className="menu_item">
                                    <a href={'/'}>GIỚI THIỆU</a>
                                </div>
                            </div>

                            <div style={{height: "64px"}}>
                                <div onClick={() => {
                                    history('/list-blog')
                                }} className="menu_item">
                                    <a>TIN TỨC</a>
                                </div>
                            </div>
                            <div style={{height: "64px"}}>
                                <div onClick={() => {
                                    if (localStorage.getItem("token")) {
                                        history('/lessons')
                                    } else {
                                        history('/login')
                                    }
                                }} className="menu_item">
                                    <a>BÀI GIẢNG</a>
                                </div>
                            </div>
                            <div style={{height: "64px"}}>
                                <div onClick={() => {
                                    history('/contact')
                                }} className="menu_item">
                                    <a>LIÊN HỆ</a>
                                </div>
                            </div>
                        </div>
                        <div style={{height: "64px"}}>
                            {localStorage.getItem("token") ?
                                <div className="menu_item">
                                    <a onClick={() => {
                                        localStorage.removeItem("token")
                                        window.location.reload()
                                    }}>ĐĂNG XUẤT</a>
                                </div> : <div className="menu_item">
                                    <a href="/login">ĐĂNG NHẬP</a>
                                </div>}
                        </div>

                    </div>

                </div>
            </Container>
        </Paper>

    );
};

export default HeaderFinal;
