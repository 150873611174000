import React from 'react';
import BmosTable from "../../components/BmosTable";
import {Tooltip} from 'antd';
import {MdEditSquare} from "react-icons/md";

const UserPage = ({listUser, onEdit}) => {
    const column = [
        {
            title: 'Tên tài khoản',
            dataIndex: 'username',
            align: 'left',
            sorter: true,
        },
        {
            title: 'Quyền',
            dataIndex: 'role',
            align: 'left',
            sorter: true,
            render: (text, record) => {
                if (text === 0) {
                    return <div>
                        ADMIN
                    </div>
                }
                if (text === 1) {
                    return <div>
                        USER
                    </div>
                }
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isEnable',
            align: 'left',
            render: (text, record) => {
                if (text === false) {
                    return <div>
                        <span style={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "normal",
                            padding: "4px 6px",
                            color: "white",
                            borderRadius: "4px",
                            backgroundColor: "#ff6a6a",
                            width: "150px",
                        }}>
                        VÔ HIỆU HÓA
                    </span>
                    </div>
                }
                if (text === true) {
                    return <div>
                        <span style={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "normal",
                            padding: "4px 6px",
                            color: "white",
                            borderRadius: "4px",
                            backgroundColor: "#37d937",
                            width: "150px",
                        }}>
                        KÍCH HOẠT
                    </span>
                    </div>
                }
            },
            sorter: true,
        },
        {
            title: 'Chức năng',
            dataIndex: 'action',
            width: 160,
            align: 'center',
            render: (text, record) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{margin: "0 10px"}}>
                        <Tooltip title={"Chỉnh sửa"}>
                            <MdEditSquare onClick={() => {
                                onEdit(record)
                            }} size={24}/>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]

    return (
        <BmosTable
            isLoading={false}
            disableClickRowExpand={false}
            columns={column}
            data={listUser}
            pagination
            totalRecord={listUser.length}
        />
    );
};

export default UserPage;