import React, {useEffect, useState} from 'react'
import {Pagination, Select} from 'antd'
import PropTypes from 'prop-types'
import {useMeasure} from 'react-use'
import {TableEtn, ContainerPagination} from './style'
import {
    ASCENDING_TOOLTIP,
    CANCEL_TOOLTIP,
    DESCENDING_TOOLTIP
} from './constants'
import _ from 'lodash';
import loadingIcon from './loading.svg'


const BmosTable = ({
                       columns,
                       data,
                       pagination,
                       rowSelection,
                       onChangePagination,
                       isLoading,
                       totalRecord,
                       onResetPagination,
                       disableClickRowExpand,
                       // loadingIcon,
                       headerBackgroundColor,
                       hoverBackgroundColor,
                       onClickRow,
                       minWidth
                   }) => {
    const [dataTable, setDataTable] = useState([])
    const [page, setPage] = useState(1)
    const [size, setPageSize] = useState(10)
    const [totalList, setTotalList] = useState([])
    useEffect(() => {
        setDataTable(data)
    }, [data])

    useEffect(() => {
        if (onResetPagination) {
            setPage(1)
            setPageSize(10)
        }
    }, [onResetPagination])

    useEffect(() => {
        if (totalRecord <= 10) {
            setTotalList([10, 50])
        } else if (totalRecord <= 50) {
            setTotalList([10, 50])
        } else if (totalRecord <= 100) {
            setTotalList([10, 50, 100])
        } else if (totalRecord <= 200) {
            setTotalList([10, 50, 100, 200])
        } else if (totalRecord <= 300) {
            setTotalList([10, 50, 100, 200, 300])
        } else if (totalRecord <= 500) {
            setTotalList([10, 50, 100, 200, 300, 500])
        } else if (totalRecord > 500) {
            setTotalList([10, 50, 100, 200, 300, 500, 1000])
        }
    }, [totalRecord])

    const expandRow = (elementList) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of elementList) {
            const item = element.children
            if (item.length !== 0)
                if (item[0].style.maxHeight === 'none') {
                    item[0].style.maxHeight = '22px'
                } else {
                    item[0].style.maxHeight = 'none'
                }
        }
    }

    const clickRow = (e, record, rowIndex) => {
        // onClickRow(record, rowIndex)
        if (!disableClickRowExpand) {
            const elementList = e.target.parentElement.children
            const spanList = e.target.parentElement.parentElement.children
            if (elementList.length === 1) {
                expandRow(spanList)
            } else {
                expandRow(elementList)
            }
        }
    }

    const onChangeSorter = (sorter) => {
        console.log("check 123")
        if (sorter.order === 'ascend') {
            setDataTable(_.sortBy([...dataTable], [function (o) {
                return o[sorter.field].toLowerCase()
            }], ['asc', 'desc']))
        }
        if (sorter.order === 'descend') {
            setDataTable(_.reverse([...dataTable], [function (o) {
                return o[sorter.field].toLowerCase()
            }]))
        }
        if (sorter.order === undefined) {
            setDataTable(data)
        }
    }

    const [ref, {height}] = useMeasure()
    return (
        <div ref={ref} style={{height: '100%', overflow: 'hidden'}}>
            <TableEtn
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataTable}
                scroll={{x: minWidth || 1200, y: height - 120}}
                pagination={{
                    current: page,
                    pageSize: size
                }}
                locale={{
                    triggerDesc: DESCENDING_TOOLTIP,
                    triggerAsc: ASCENDING_TOOLTIP,
                    cancelSort: CANCEL_TOOLTIP
                }}
                loading={{
                    indicator: (
                        <img
                            style={{width: '70px', height: '70px'}}
                            alt=''
                            src={loadingIcon}
                        />
                    ),
                    spinning: isLoading
                }}
                onChange={(paginations, filters, sorter) => {
                    console.log(sorter)
                    if (onChangeSorter) onChangeSorter(sorter)
                }}
                onRow={(record, rowIndex) => ({
                    onClick: (e) => clickRow(e, record, rowIndex)
                })}
                headerBackgroundColor={headerBackgroundColor}
                hoverBackgroundColor={hoverBackgroundColor}
            />
            {data.length !== 0 && (
                <ContainerPagination pagination={pagination}>
                    <div style={{marginRight: '15px'}}>Tổng số: {totalRecord}</div>
                    <div style={{marginRight: '5px'}}>
                        Số hàng trên mỗi trang:
                    </div>
                    <Select
                        value={size}
                        onChange={(selected) => {
                            setPageSize(selected)
                            setPage(1)
                            if (onChangePagination) onChangePagination(selected, 1)
                        }}
                    >
                        {totalList.map((value) => (
                            <Select value={value}>{value}</Select>
                        ))}
                    </Select>
                    <Pagination
                        simple
                        defaultCurrent={1}
                        total={totalRecord}
                        showTotal={false}
                        current={page}
                        pageSize={size}
                        onChange={(current, pageSize) => {
                            setPage(current)
                            setPageSize(pageSize)
                            if (onChangePagination) onChangePagination(pageSize, current)
                        }}
                    />
                </ContainerPagination>
            )}
        </div>
    )
}
BmosTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    pagination: PropTypes.bool,
    rowSelection: PropTypes.bool,
    onChangePagination: PropTypes.func,
    isLoading: PropTypes.bool,
    onChangeSorter: PropTypes.func,
    totalRecord: PropTypes.number,
    onResetPagination: PropTypes.bool,
    disableClickRow: PropTypes.bool
}
export default BmosTable
