import React, {useEffect} from 'react';
import {Container} from "./style";

function ViewPPPTX() {

    const asPath = window.location.pathname;
    const lastIndex = asPath.lastIndexOf('/');
    const url = asPath.substring(lastIndex + 1, asPath.length); // remove preview

    console.log(url)


    return (
        <Container>
            <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                width={"100%"}
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                height={"100%"}
                frameBorder={"0"}
            />
        </Container>
    );
}

export default ViewPPPTX;
