import React, {useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, FormControl, TextField} from "@mui/material";
import LOGO from '../../image/logo.jpg'
import BACKGROUND from '../../image/background.jpg'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import HeaderFinal from "../../components/HeaderFinal";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BANNER_1 from '../../image/Banner1.jpg'
import BANNER_3 from '../../image/Banner3.jpg'
import {Container} from "./style";

import IMAGE_WHAT from '../../image/kynagsonglagi.png'
import Footer from "../../components/Footer";
import MOCK_BLOG from "../../image/mock_blog.jpg";
import moment from "moment/moment";

const SliderCustom = styled(Slider)`
  //position: absolute;
  //width: 100vw;
  height: max-content;
  overflow: hidden;

  .slick-dots {
    bottom: 20px;
    color: white;
  }

  .slick-arrow, .slick-prev {
    display: none;
  }

  .slick-slider {
  }
`


const SliderImage = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${props => props.url});
  width: 100vw;
  height: 50vh;
  background-size: cover;
  @media (max-width: 700px) {
    height: 40vh;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`


const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    lazyLoad: true,
    speed: 1000,
};

const LIST_BACKGROUND = [
    {
        id: "1",
        url: BANNER_1
    },
    {
        id: "2",
        url: BANNER_3
    }
]

const Home = () => {
    const history = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [listBlog, setListBlog] = useState([])

    useEffect(() => {
        axios.get(`${window.SystemConfig.URL}/Blog/getAll`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                let array = res.data.sort((a, b) => b.createAt - a.createAt)
                setListBlog(array.slice(0, 3))
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [])


    return (
        <div style={{overflow: "hidden"}}>
            <HeaderFinal/>
            <Container>
                <SliderCustom {...settings}>
                    {LIST_BACKGROUND.map((item) => {
                        return <SliderImage url={item.url}/>
                    })}
                </SliderCustom>


                {isLoading && <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>}

                <div className={"ky_nang_song_la_gi"}>
                    <div className={"text"}>
                        <h1>Giáo dục kỹ năng sống là gì?</h1>
                        <h2>
                            Giáo dục kỹ năng sống là một quá trình tác động sư phạm có mục đích, có kế hoạch nhằm hình
                            thành năng lực hành động tích cực, có liên quan tới kiến thức và thái độ, giúp cá nhân có ý
                            thức về bản thân, giao tiếp, quan hệ xã hội, thực hiện công việc, ứng phó hiệu quả với các
                            yêu cầu thách thức của cuộc sống hàng ngày…
                        </h2>
                        <h2>
                            Kỹ năng sống được hình thành theo nhiều cách khác nhau, tùy vào môi trường sống và giáo dục…
                        </h2>
                        <h2>
                            Trong giáo dục kỹ năng sống cho trẻ mầm non, mẫu giáo, đó là những hoạt động tích cực, hướng
                            vào những hoạt động cá nhân hoặc một nhóm trẻ với mục đích giúp trẻ có thể ứng phó hiệu quả
                            với các tình huống, thách thức trong cuộc sống hàng ngày. Định hướng của giáo dục kỹ năng
                            sống cho trẻ là giúp trẻ làm chủ bản thân, ứng xử phù hợp với cộng đồng và xã hội, thích
                            nghi, học tập hiệu quả, nâng cao sức khỏe tinh thần và thể chất, ứng phó tích cực trong các
                            tình huống của cuộc sống.
                        </h2>
                    </div>

                    <img src={IMAGE_WHAT} alt=""/>

                </div>

                <div style={{margin: "20px auto", fontWeight: "bold", width: "80%"}}>
                    CÁC BÀI VIẾT GẦN ĐÂY
                </div>
                <div className={"row"}>
                    {listBlog.map((item) => {
                        return (
                            <div onClick={() => {
                                history(`/blog/${item.id}`)
                            }} className={"item"}>
                                <img
                                    src={window.SystemConfig.LINK + "/" + item.img || MOCK_BLOG}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = MOCK_BLOG;
                                    }}
                                    alt=""
                                />
                                <div className={"text"}>
                                    <h1>{item.name}</h1>
                                    <h2>{item.description}</h2>
                                    <div className="time">
                                        {moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </Container>
            <Footer/>
        </div>
    );
};

export default Home;