import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {Container} from "./style";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'
import ReactQuill from "react-quill";
import MOCK_BLOG from '../../image/mock_blog.jpg'
import {Backdrop, CircularProgress} from "@mui/material";
import moment from "moment";
import HeaderFinal from "../../components/HeaderFinal";
import Footer from "../../components/Footer";

function getRandomElementsFromArray(arr, num) {
    // Kiểm tra xem số phần tử muốn lấy có lớn hơn tổng số phần tử trong mảng hay không
    if (num >= arr.length) {
        return arr;
    }

    // Shuffle (trộn lẫn) mảng để lấy ngẫu nhiên
    const shuffledArray = arr.sort(() => 0.5 - Math.random());

    // Trích xuất các phần tử ngẫu nhiên
    return shuffledArray.slice(0, num);
}

const PreviewBlog = () => {
    const location = useLocation();
    document.title = 'Blog';
    const history = useNavigate()
    const asPath = window.location.pathname;
    const lastIndex = asPath.lastIndexOf('/');
    const id = asPath.substring(lastIndex + 1, asPath.length); // remove preview
    const [dataBlog, setDataBlog] = useState(null)
    const [html, setHtml] = useState(null)
    const [listBlog, setListBlog] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        axios.get(`${window.SystemConfig.URL}/Blog/getAll`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then((res) => {
                const randomElements = getRandomElementsFromArray(res.data, 3);
                setListBlog(randomElements)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        setIsLoading(true)
        axios.get(`${window.SystemConfig.URL}/Blog/getById/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
            .then(response => {
                setDataBlog(response.data)
                axios.get(`${window.SystemConfig.URL}/blog/getBlockDataFile/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`}})
                    .then(response => {
                        setIsLoading(false)
                        setHtml(response.data.data)
                    })
                    .catch(error => {
                        console.error('Error uploading file:', error);
                    });
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    }, [id])

    const parsedHtml = {__html: html};

    return (
        <Container>
            {isLoading && <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>}
            <HeaderFinal/>
            <div style={{width: "80vw", margin: "auto"}}>
                <div className={"blog"}>
                    <div className={"name"}>
                        {dataBlog?.name}
                    </div>
                    <ReactQuill
                        value={html}
                        readOnly={true}
                        theme={"bubble"}
                    />
                </div>
                <div style={{margin: "20px 0", fontWeight: "bold"}}>
                    CÁC BÀI VIẾT KHÁC
                </div>
                <div className={"row"}>
                    {listBlog.map((item) => {
                        return (
                            <div onClick={() => {
                                history(`/blog/${item.id}`)
                            }} className={"item"}>
                                <img
                                    src={window.SystemConfig.LINK + "/" + item.img || MOCK_BLOG}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = MOCK_BLOG;
                                    }}
                                    alt=""
                                />
                                <div className={"text"}>
                                    <h1>{item.name}</h1>
                                    <h2>{item.description}</h2>
                                    <div className="time">
                                        {moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer/>
        </Container>
    );
}
    ;

    export default PreviewBlog;