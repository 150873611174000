import styled from "styled-components";

export const Container = styled.div`
  margin-top: 60px;
  margin-bottom: 120px;


  .ky_nang_song_la_gi {
    margin-top: 80px;
    display: flex;
    justify-content: center;

    .text {
      margin-right: 20px;
      width: 35%;
      font-weight: normal;

      h1 {
        color: #92d050;
        font-size: 20px;
      }

      h2 {
        font-size: 16px;
        font-weight: normal;
      }
    }

    img {
      margin-left: 20px;
      width: auto;
      height: 400px;
    }
  }


  .row {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    margin-top: 20px;

    .item {
      cursor: pointer;
      width: 32%;
      display: flex;
      border: 1px solid #cccccc;
      justify-content: space-between;
      position: relative;

      .time {
        position: absolute;
        bottom: 10px;
        right: 6px;
        font-size: 12px;
        font-style: italic;
      }

      img {
        height: 180px;
        width: 35%;
        object-fit: cover;
      }

      .text {
        width: 62%;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 12px;
        }

        h2 {
          display: -webkit-box;
          max-width: 400px;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          font-weight: normal;
        }
      }
    }

`